<template>
  <dx-util-popup
    ref="buyCostUpdaterPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    width="800px"
    height="auto"
    @shown="buyCostUpdaterPopupShown"
    @hidden="buyCostUpdaterPopupHidden"
  >
  <div>
    <div class="alert alert-warning">
        If you want to apply this buy cost to a certain period, please click "Apply All" check box.
      </div>
    <div class="d-flex flew-wrap align-items-center">
      <div class="px-half">
        <label for="newBuyCost">New Buy Cost</label>
        <dx-util-number-box id="newBuyCost" v-model="newBuyCost" />
      </div>
      <div class="px-half pt-1">
        <dx-util-check-box
          id="applyAll"
          v-model="applyAll"
          class="text-warning font-weight-bolder"
          text="Apply All"
        />
      </div>
      <div v-if="applyAll" class="px-half">
        <label for="startDateDateBox">Start Date</label>
        <dx-util-date-box
          id="startDateDateBox"
          v-model="startDate"
          placeholder="Start Date"
          :max="endDate"
          type="date"
          date-serialization-format="yyyy-MM-dd"
        />
      </div>
      <div v-if="applyAll" class="px-half">
        <label for="endDateDateBox">End Date</label>
        <dx-util-date-box
          id="endDateDateBox"
          v-model="endDate"
          placeholder="End Date"
          :min="startDate"
          type="date"
          date-serialization-format="yyyy-MM-dd"
        />
      </div>
    </div>
  </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'

const today = new Date()
export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      newBuyCost: null,
      applyAll: false,
      startDate: today,
      endDate: today,
    }
  },
  computed: {
    buyCostUpdaterPopup() {
      return this.$refs.buyCostUpdaterPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.buyCostUpdaterPopup.show()
        this.newBuyCost = this.row.totalCOGS
      },
    },
  },
  methods: {
    buyCostUpdaterPopupShown() {
      this.popupTitle = 'Update Buy Cost'
    },
    buyCostUpdaterPopupHidden() {
    // Clear form when popup is closed
      this.newBuyCost = null
      this.applyAll = false
      this.startDate = today
      this.endDate = today
    },
    closePopup() {
      this.buyCostUpdaterPopup.hide()
    },
    async handleSubmit() {
      const payload = {
        id: this.row.id,
        cost: this.newBuyCost,
        applyAll: this.applyAll,
        startDate: this.startDate,
        endDate: this.endDate,
      }
      const result = await amazonReportService.updateBuyCost(payload)
      Notify.success(`buy cost has been updated, Item count: ${result}`)

      this.$emit('emit-form-saved')
      this.closePopup()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
